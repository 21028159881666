import React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/react";
import Descriptor from "../../components/Descriptor";
import Image from "../../components/image";
import InnerContainer from "../../components/InnerContainer";
import CtaButton from "../../components/CtaButton";

const IntroComponent = styled.div`
  padding: 4rem 1.5rem;
  background-color: ${props => props.theme.colors.lightPeach};

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
    padding: 6rem;
  }
  /* justify-content: space-between; */
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    flex-direction: row;
  }
`;

const IntroLeft = styled.div`
  width: 50%;
  padding: 0;
  margin-bottom: 3rem;
  display: flex;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    width: 17rem;
    max-width: 17rem;
    margin-right: 10rem;
    /* padding: 2.5rem 6rem; */
  }
`;

const IntroLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    align-items: center;
  } ;
`;

const Icon = styled(Image)`
  width: 5.45rem;
  height: 5.45rem;
  margin-top: 3rem;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    /* margin: 3rem auto 0; */
  }
`;

const IntroRight = styled.div`
  width: 100%;

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    /* width: 65%; */
    max-width: 99rem;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.xl}) {
    /* width: 50%; */
  }
`;

const IntroTitle = styled.div`
  margin-bottom: 4rem;

  p {
    font-size: 4rem;
    font-family: ${props => props.theme.fonts.light};
    font-weight: 300;
    line-height: 54px;
    /* color: ${props => props.theme.colors.darkGold}; */
    /* padding-bottom: 4rem; */
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 4.8rem;
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      font-size: 6.5rem;
      line-height: 80px;
    }

    strong {
      font-family: ${props => props.theme.fonts.medium};
      font-weight: 500;
    }
  }
`;

const IntroParagraphs = styled.div`
  p {
    font-size: 2.3rem;
    font-family: ${props => props.theme.fonts.regular};
    line-height: 36px;
    margin-bottom: 0;
    color: ${props => props.theme.colors.offBlack};
    @media (min-width: ${props => props.theme.breakpoints.md}) {
      font-size: 2.5rem;
      line-height: 39px;
      margin-bottom: 3rem;
    }
  }
`;

function createTitle({ title }) {
  return { __html: title };
}

function createParagraphs({ paragraphs }) {
  return { __html: paragraphs };
}

const Introduction = props => {
  return (
    <IntroComponent>
      <InnerContainer>
        <FlexContainer>
          <IntroLeft>
            <IntroLeftContainer>
              <Descriptor
                title={props.descriptor}
                color={props.descriptorColor}
                alignment={props.underlineAlignment}
                align="center"
              />
              {props.icon && <Icon image={props.icon} />}
            </IntroLeftContainer>
          </IntroLeft>
          <IntroRight>
            <IntroTitle
              dangerouslySetInnerHTML={createTitle(props)}
              css={css`
                color: ${props.titleColor};
              `}
            />
            <IntroParagraphs
              dangerouslySetInnerHTML={createParagraphs(props)}
            />
            {props.buttonLink && (
              <CtaButton
                linkType={props.buttonLinkType}
                email={props.buttonEmail}
                text={props.buttonText}
                type={props.buttonType}
                color={props.buttonColor}
                link={props.buttonLink}
              />
            )}
          </IntroRight>
        </FlexContainer>
      </InnerContainer>
    </IntroComponent>
  );
};

export default Introduction;
